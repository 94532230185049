<template>
  <nav class="mobile-menu fixed z-50 flex size-full flex-col bg-[--mobile-menu-bg-color] text-accent-200">
    <header class="flex h-20 shrink-0 items-center gap-x-3 px-8 pb-3.5 pt-8" :class="{ 'h-24': !isAuthenticated }">
      <div class="grow pr-6" :class="{ 'pointer-events-none': !isAuthenticated || user?.passwordExpired }">
        <VcImage
          :src="$cfg.logo_inverted_image"
          :alt="$context.storeName"
          class="max-h-14 cursor-pointer"
          lazy
          @click="
            $emit('close');
            router.push('/home');
          "
        />
      </div>

      <!-- Language block -->
      <LanguageSelector v-if="supportedLocales.length > 1" />
      <router-link
        v-if="$cfg.push_messages_enabled && isAuthenticated"
        :to="{ name: 'Notifications' }"
        class="-mr-4 appearance-none p-4"
        @click="$emit('close')"
      >
        <svg class="text-primary-200" height="32" width="32">
          <use href="/static/images/opus/icons/bell-mobile.svg#main" />
        </svg>
      </router-link>
      <button type="button" class="-mr-4 appearance-none p-4" @click="$emit('close')">
        <svg class="text-[--mobile-menu-navigation-color]" height="20" width="20">
          <use href="/static/images/close.svg#main" />
        </svg>
      </button>
    </header>

    <!-- region Children links section -->
    <section v-if="openedItem" class="grow divide-y-2 divide-additional-50 divide-opacity-10 overflow-y-auto pt-5">
      <div class="flex flex-col px-10 py-6">
        <button type="button" class="appearance-none self-start text-[--mobile-menu-navigation-color]" @click="goBack">
          <VcIcon name="arrow-circle-left" size="lg" />
        </button>

        <h2
          v-if="openedItem?.title && openedItem.title != 'Agency'"
          class="mt-5 text-2xl tracking-[0.01em] text-additional-50"
        >
          {{ openedItem?.title }}
        </h2>
        <h2
          v-if="openedItem.title && openedItem.title == 'Agency'"
          class="mt-5 text-2xl tracking-[0.01em] text-additional-50"
        >
          {{ user.contact?.firstName }}
        </h2>

        <ul class="mt-4 flex flex-col gap-y-2">
          <li v-for="childItem in openedItem?.children" :key="childItem.title">
            <!-- Contact organizations -->
            <!-- OPUS -->
            <!-- <div
              v-if="openedItem.id === 'contact-organizations' && !!childItem.id"
              class="flex grow flex-col gap-y-1 font-normal"
            >
              <VcRadioButton
                v-model="contactOrganizationId"
                :value="childItem.id"
                class="py-2.5"
                @change="selectOrganization"
              >
                <span class="uppercase">
                  {{ childItem.title }}
                </span>
              </VcRadioButton>
            </div> -->
            <!-- !OPUS -->

            <!-- Currency setting -->
            <div v-if="childItem.id === 'currency-setting'" class="flex grow flex-col gap-y-1 font-normal">
              <header class="-mt-1 mb-1 text-2xl uppercase text-additional-50">
                {{ $t("shared.layout.header.mobile.currency") }}
              </header>

              <VcRadioButton
                v-for="currencyItem in supportedCurrencies"
                :key="currencyItem.code"
                v-model="currentCurrency.code"
                :value="currencyItem.code"
                class="py-2.5"
                @click="currentCurrency?.code === currencyItem.code ? null : saveCurrencyCode(currencyItem.code)"
              >
                <span :class="{ 'text-additional-50': currentCurrency?.code === currencyItem.code }" class="uppercase">
                  {{ currencyItem.code }}
                </span>
              </VcRadioButton>
            </div>

            <!-- OPUS -->
            <template v-else-if="childItem.id === 'orders_for_approval'">
              <MobileMenuLink
                v-if="checkPermissions(OpusPermissions.CanApproveOrders)"
                :link="childItem"
                class="py-1 text-lg"
                @close="$emit('close')"
                @select="selectMenuItem(childItem)"
              >
                {{ childItem.title }}
              </MobileMenuLink>
            </template>

            <template
              v-else-if="
                childItem.id === 'supplier_management' ||
                childItem.id === 'company_info' ||
                childItem.id === 'company_members'
              "
            >
              <MobileMenuLink
                v-if="isCorporateMember"
                :link="childItem"
                class="py-1 text-lg"
                @close="$emit('close')"
                @select="selectMenuItem(childItem)"
              >
                {{ childItem.title }}
              </MobileMenuLink>
            </template>

            <template v-else-if="childItem.id === 'notifications'">
              <MobileMenuLink
                v-if="$cfg.push_messages_enabled"
                :link="childItem"
                class="py-1 text-lg"
                @close="$emit('close')"
                @select="selectMenuItem(childItem)"
              >
                {{ childItem.title }}
              </MobileMenuLink>
            </template>

            <template v-else-if="childItem.id === 'approvals'">
              <MobileMenuLink
                v-if="userCanApproveOrders"
                :link="childItem"
                class="py-1 text-lg"
                @close="$emit('close')"
                @select="selectMenuItem(childItem)"
              >
                {{ childItem.title }}
              </MobileMenuLink>
            </template>

            <template v-else-if="childItem.id === 'tax_certificates'">
              <MobileMenuLink
                v-if="taxCertificatesStorageEnabled"
                :link="childItem"
                class="py-1 text-lg"
                @close="$emit('close')"
                @select="selectMenuItem(childItem)"
              >
                {{ childItem.title }}
              </MobileMenuLink>
            </template>
            <!-- !OPUS -->

            <!-- TODO: Remove rendering by condition -->
            <MobileMenuLink
              v-else-if="
                (childItem.id !== 'quotes' || $cfg.quotes_enabled) &&
                (childItem.id !== 'addresses' || !isCorporateMember) &&
                openedItem.id !== 'contact-organizations'
              "
              :link="childItem"
              class="py-1 text-lg"
              @close="$emit('close')"
              @select="selectMenuItem(childItem)"
            >
              {{ childItem.title }}
            </MobileMenuLink>
          </li>
        </ul>

        <template v-if="openedItem?.isCatalogItem && openedItem?.route">
          <div class="my-5 h-px bg-gradient-to-r from-accent to-transparent"></div>

          <a
            v-if="isExternalLink(openedItem.route)"
            class="view-all-link"
            :href="openedItem.route as string"
            target="_blank"
            @click="$emit('close')"
          >
            {{ $t("shared.layout.header.mobile.view_all_catalog") }}
          </a>
          <router-link v-else class="view-all-link" :to="openedItem.route" @click="$emit('close')">
            {{ $t("shared.layout.header.mobile.view_all_catalog") }}
          </router-link>
        </template>
      </div>
    </section>
    <!-- endregion Children links section -->

    <!-- region Main menu section -->
    <section v-else class="grow divide-y divide-additional-50 divide-opacity-20 overflow-y-auto">
      <div v-if="!isAuthenticated" class="flex flex-col gap-y-2">
        <div
          class="z-0 mb-8 mt-7 flex items-center bg-[color:var(--color-link-light)] text-15 font-normal text-[color:var(--color-footer-bottom-bg)]"
          :class="{ 'mobile-unauthorized-warning ': !isAuthenticated }"
        >
          <VcImage
            alt="info-icon"
            src="/static/images/opus/icons/information-circle.svg"
            class="mx-3 text-[color:var(--color-footer-bottom-bg)]"
            lazy
          />

          <span class="ml-0 mr-auto">{{ $t("shared.layout.header.bottom_header.warning_message") }}</span>
        </div>
      </div>
      <div
        v-if="isAuthenticated"
        :class="user?.passwordExpired ? 'pointer-events-none opacity-25' : ''"
        class="flex flex-col gap-y-2 px-9 py-6"
      >
        <!-- OPUS -->
        <!-- Home link -->
        <!-- <MobileMenuLink :link="homeLink" class="py-1 text-2xl" @close="$emit('close')">
          {{ homeLink.title }}
        </MobileMenuLink> -->
        <!-- !OPUS -->
        <template v-for="item in mobileMainMenuItems" :key="item.title">
          <MobileMenuLink
            v-if="item.id === 'cart'"
            :link="item"
            :disabled="user?.passwordExpired"
            :count="cart?.itemsQuantity"
            class="py-1 text-lg font-normal"
            @select="selectMenuItem(item)"
            @close="$emit('close')"
          >
            {{ item.title }}
          </MobileMenuLink>

          <MobileMenuLink
            v-else-if="item.id === 'compare'"
            :link="item"
            :disabled="user?.passwordExpired"
            :count="productsIds.length"
            class="py-1 text-2xl font-normal"
            @close="$emit('close')"
            @select="selectMenuItem(item)"
          >
            {{ item.title }}
          </MobileMenuLink>

          <!-- OPUS -->
          <span
            v-else-if="item.id === 'contacts'"
            class="flex min-h-9 items-center gap-x-3.5 py-1 text-left text-lg leading-tight"
            tabindex="0"
            role="button"
            @click="openContactUsModal"
            @keydown.enter="openContactUsModal"
          >
            <svg v-if="item.icon" height="36" width="36" class="shrink-0 text-[color:var(--color-mobile-menu-icon)]">
              <use :href="item.icon" />
            </svg>
            <span class="overflow-wrap-normal word-break-normal line-clamp-3 whitespace-nowrap font-normal">
              {{ item.title }}
            </span>
          </span>
          <!-- OPUS -->
          <MobileMenuLink
            v-else-if="item.id === 'orders_for_approval_main'"
            :link="item"
            :class="{ hidden: !userCanApproveOrders }"
            :disabled="user?.passwordExpired"
            class="py-1 text-lg font-normal"
            @close="$emit('close')"
            @select="selectMenuItem(item)"
          >
            {{ item.title }}
          </MobileMenuLink>
          <!-- !OPUS -->

          <MobileMenuLink
            v-else
            :link="item"
            :disabled="user?.passwordExpired"
            class="py-1 text-lg font-normal"
            @close="$emit('close')"
            @select="selectMenuItem(item)"
          >
            {{ item.title }}
          </MobileMenuLink>
        </template>
      </div>
      <!-- GUIDE -->
      <div
        v-if="isAuthenticated"
        :class="user?.passwordExpired ? 'pointer-events-none opacity-25' : ''"
        class="flex flex-col gap-y-2 px-9 py-6 font-normal"
      >
        <template v-for="item in mobileGuideMenuItems" :key="item.title">
          <!-- OPUS -->
          <span
            v-if="item.id === 'contacts'"
            class="flex min-h-9 items-center gap-x-3.5 py-1 text-left text-lg leading-tight text-[--mobile-menu-link-color]"
            tabindex="0"
            role="button"
            @click="openContactUsModal"
            @keydown.enter="openContactUsModal"
          >
            <svg v-if="item.icon" height="36" width="36" class="shrink-0 text-[color:var(--color-mobile-menu-icon)]">
              <use :href="item.icon" />
            </svg>
            <span class="overflow-wrap-normal word-break-normal line-clamp-3 whitespace-nowrap font-normal">
              {{ item.title }}
            </span>
          </span>
          <!-- !OPUS -->
          <MobileMenuLink
            v-else
            :link="item"
            class="py-1 text-lg font-normal"
            :disabled="user?.passwordExpired"
            @close="$emit('close')"
            @select="selectMenuItem(item)"
          >
            {{ item.title }}
          </MobileMenuLink>
        </template>
      </div>
      <!-- ENDGUIDE -->
      <div class="flex flex-col gap-y-2 px-9 py-6">
        <template v-if="isAuthenticated">
          <!-- Account -->
          <!-- <div class="mt-2 mb-4 flex flex-row gap-4 text-xl">
            <div
              class="flex size-12 shrink-0 items-center justify-center overflow-hidden rounded-full ring-2 ring-accent-300"
            >
              <VcImage v-if="user.photoUrl" :src="user.photoUrl" :alt="user.contact?.fullName" class="size-12" lazy />
              <VcIcon v-else name="user" />
            </div>
            <div class="flex flex-col leading-tight">
              <div class="flex flex-wrap items-center gap-x-1 text-accent-100">
                <template v-if="operator">
                  <span class="line-clamp-3 font-bold [word-break:break-word]">
                    {{ operator.contact?.fullName || operator.userName }}
                  </span>

                  <span class="text-accent-200">
                    {{ $t("shared.layout.header.top_header.logged_in_as") }}
                  </span>
                </template>

                <span class="mr-1 font-bold line-clamp-3 [word-break:break-word]">
                  {{ user.contact?.fullName || user.userName }}
                </span>
              </div>

              <div>
                <button type="button" class="font-bold text-[--mobile-menu-navigation-color]" @click="signMeOut">
                  {{ $t("shared.layout.header.link_logout") }}
                </button>
              </div>
            </div>
          </div> -->

          <!-- Account link -->
          <MobileMenuLink
            v-if="mobileCorporateMenuItem"
            :link="mobileCorporateMenuItem"
            class="py-1 text-lg font-normal"
            :class="user?.passwordExpired ? 'pointer-events-none opacity-25' : ''"
            @select="selectMenuItem(mobileCorporateMenuItem!)"
          >
            {{ user.contact?.firstName }}
          </MobileMenuLink>

          <span
            v-if="organization"
            class="my-2 ml-12 line-clamp-2 text-xl font-normal italic leading-[22px] text-[#D6F0FD]"
          >
            {{ organization?.name }}
          </span>
          <VcButton class="ml-12 w-32 !bg-accent text-13 font-bold" size="sm" @click="signMeOut()">
            {{ $t("shared.layout.header.link_logout") }}
          </VcButton>
          <!-- OPUS -->
          <!-- Corporate link -->
          <!-- <MobileMenuLink
            v-if="mobileCorporateMenuLink && organization"
            :link="mobileCorporateMenuLink"
            class="py-1 text-2xl"
            @select="selectMenuItem(mobileCorporateMenuItem!)"
          >
            {{ mobileCorporateMenuLink.title }}
          </MobileMenuLink> -->
          <!-- !OPUS -->
        </template>

        <!-- Unauthorized links -->
        <div v-else class="mb-1">
          <MobileMenuLink
            v-for="item in unauthorizedMenuItems"
            :key="item.title"
            :link="item"
            class="py-1.5 text-lg font-normal"
            @close="$emit('close')"
            @select="selectMenuItem(item)"
          >
            {{ item.title }}
          </MobileMenuLink>
        </div>

        <!-- Settings link -->
        <MobileMenuLink
          v-if="supportedCurrencies.length > 1"
          :link="settingsMenuItem"
          class="py-1 text-lg font-normal"
          @select="selectMenuItem(settingsMenuItem)"
        >
          {{ $t("shared.layout.header.mobile.settings") }}
        </MobileMenuLink>
      </div>
    </section>
    <!-- endregion Main menu section -->
    <!-- Footer -->
    <div class="mx-10 mb-12 flex flex-col pt-2 text-15 text-additional-50">
      <div class="mb-2 flex flex-row items-center justify-between">
        <a target="_blank" href="https://info.omniapartners.com/">
          <img src="/static/images/opus/omnia-footer-logo.svg#main" alt="omnia" class="h-12" lazy />
        </a>
        <a class="ml-auto mr-0" target="_blank" href="https://www.linkedin.com/company/omnia-partners">
          <img class="mr-2 h-9" src="/static/icons/social/linkedin-icon.svg" alt="linkedIn" lazy />
        </a>
        <a target="_blank" href="https://www.youtube.com/@omniapartners">
          <img class="h-9" src="/static/icons/social/youtube-icon.svg" alt="youtube" lazy />
        </a>
      </div>
      <div class="flex flex-row items-center justify-center">
        <router-link
          target="_blank"
          :to="{ name: 'TermsAndConditions', params: { sectionName: 'Terms&Conditions' } }"
          class="ml-1"
        >
          {{ $t("shared.layout.footer.terms_of_use_link") }}
        </router-link>
      </div>
    </div>
    <div
      class="mobile-menu__overlay fixed inset-y-0 right-0 hidden bg-additional-950/5 backdrop-blur-lg md:block"
      tabindex="0"
      role="button"
      aria-label="Close"
      @click="$emit('close')"
      @keydown.esc="$emit('close')"
    />
  </nav>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { RouterLink, useRouter } from "vue-router";
import { useCurrency, useNavigations, useOpus } from "@/core/composables";
import { useLanguages } from "@/core/composables/useLanguages";
import { OpusPermissions } from "@/core/enums";
import { getLinkAttr } from "@/core/utilities";
import { useSignMeOut, useUser } from "@/shared/account";
import { useShortCart } from "@/shared/cart";
import { useCompareProducts } from "@/shared/compare";
import { useModal } from "@/shared/modal";
import { OpContactUsDialog } from "@/shared/opus/common/components";
import MobileMenuLink from "./mobile-menu-link.vue";
import type { ExtendedMenuLinkType } from "@/core/types";
import type { RouteLocationRaw } from "vue-router";
import LanguageSelector from "@/shared/layout/components/language-selector/language-selector.vue";

interface IEmits {
  (event: "close"): void;
}

const emit = defineEmits<IEmits>();

const { t } = useI18n();
const { cart } = useShortCart();
const { productsIds } = useCompareProducts();
const { supportedLocales } = useLanguages();
const { currentCurrency, supportedCurrencies, saveCurrencyCode } = useCurrency();
const { user, operator, isAuthenticated, organization, isCorporateMember, switchOrganization, checkPermissions } =
  useUser();
const { signMeOut } = useSignMeOut();
const router = useRouter();
const {
  mobileMainMenuItems,
  mobileGuideMenuItems,
  mobileCorporateMenuItem,
  mobilePreSelectedMenuItem,
  openedItem,
  selectMenuItem,
  goBack,
  goMainMenu,
} = useNavigations();
const contactOrganizationId = ref(user.value?.contact?.organizationId);

const unauthorizedMenuItems: ExtendedMenuLinkType[] = [
  // { route: { name: "SignIn" }, title: t("shared.layout.header.link_sign_in") },
  {
    title: t("shared.layout.header.link_login_to_opus"),
    icon: "/static/images/opus/signin-mobile.svg#menu",
    route: { name: "SignIn" },
  },
  {
    title: t("shared.layout.header.link_register_now"),
    icon: "/static/images/opus/icons/user-circle.svg#guide",
    route: { name: "SignUp" },
  },
  // OPUS
  {
    route: { name: "AboutUs" },
    icon: "/static/images/opus/icons/case.svg#guide",
    title: t("shared.layout.header.menu.about_us"),
  },
  // !OPUS
];

const settingsMenuItem: ExtendedMenuLinkType = {
  id: "settings",
  icon: "/static/images/common/settings.svg#main",
  children: [{ id: "currency-setting" }], // see implementation in template
};

const homeMenuItem = computed<ExtendedMenuLinkType>(() =>
  isAuthenticated.value
    ? {
        route: { name: "Dashboard" },
        title: t("shared.layout.header.mobile.account_menu.dashboard"),
        icon: "/static/images/dashboard/icons/dashboard-main.svg#main",
      }
    : {
        route: "/home",
        title: t("shared.layout.header.menu.home"),
        icon: "/static/images/dashboard/icons/dashboard.svg#main",
      },
);

function isExternalLink(link?: RouteLocationRaw) {
  return "externalLink" in getLinkAttr(link);
}

async function selectOrganization(): Promise<void> {
  if (!contactOrganizationId.value) {
    return;
  }

  await switchOrganization(contactOrganizationId.value);
}

onMounted(() => {
  goMainMenu();

  if (mobilePreSelectedMenuItem.value) {
    selectMenuItem(mobilePreSelectedMenuItem.value);
  }
});

// OPUS
const { taxCertificatesStorageEnabled } = useOpus();
const userCanApproveOrders = computed(() => checkPermissions(OpusPermissions.CanApproveOrders));
const { closeModal, openModal } = useModal();

function openContactUsModal() {
  openModal({
    component: OpContactUsDialog,
    props: {
      onResult() {
        closeModal();
      },
    },
  });
}
// !OPUS
</script>

<style lang="scss" scoped>
.mobile-menu {
  --sidebar-max-width: 430px;
  --vc-radio-button-base-color: var(--mobile-menu-control-color);

  box-shadow: 5px 0 15px 0 rgba(0, 0, 0, 0.5);

  @apply md:max-w-[var(--sidebar-max-width)];
}

.view-all-link {
  @apply text-lg tracking-[0.01em] text-additional-50;
}

.mobile-menu__overlay {
  @apply left-[var(--sidebar-max-width)];
}

.is-visible .mobile-menu__overlay {
  animation: fadeIn 0.4s forwards;
}

@keyframes fadeIn {
  from {
    @apply opacity-0;
  }
  to {
    @apply opacity-100;
  }
}
// OPUS
.mobile-unauthorized-warning {
  margin-left: 32px;
  margin-right: 32px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 12px 5px;
  background: #ffffff;
  -webkit-box-shadow: 1px 2px 9px rgb(0 0 0 / 16%);
  box-shadow: 1px 2px 9px rgb(0 0 0 / 16%);
  border-radius: 4px;
}
// !OPUS
</style>
