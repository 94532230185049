import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { computed, ref } from "vue";
import { useModuleSettings } from "@/core/composables/useModuleSettings";
import { Logger } from "@/core/utilities";

const appInsights = ref<ApplicationInsights>();
const DEBUG_PREFIX = "[APPINSIGHTS]";
const MODULE_ID = "VirtoCommerce.ApplicationInsights";
const SETTINGS_MAPPING = {
  "ApplicationInsights.InstrumentationKey": "instrumentationKey",
} as const;
const { getModuleSettings } = useModuleSettings(MODULE_ID);

type ModuleSettingsType = { instrumentationKey: string };

// TODO: remove if appIns works by plugin
export function useAppInsights() {
  function loadAppInsights(): void {
    try {
      const { instrumentationKey } = getModuleSettings(SETTINGS_MAPPING) as ModuleSettingsType;
      if (instrumentationKey) {
        appInsights.value = new ApplicationInsights({
          config: {
            instrumentationKey,
          },
        });
        appInsights.value.loadAppInsights();
      }
    } catch (e) {
      Logger.error(DEBUG_PREFIX, loadAppInsights.name, e);
    }
  }

  return {
    loadAppInsights,
    appInsights: computed(() => appInsights.value),
  };
}
