<template>
  <div class="op-terms">
    <div
      class="z-10 flex justify-center bg-cover bg-no-repeat"
      :class="{
        'bg-[url(/static/images/opus/terms-and-conditions/terms-and-conditions-mobile.png)]': isMobile,
        'bg-[url(/static/images/opus/terms-and-conditions/terms-and-conditions.png)]': !isMobile,
      }"
    >
      <div class="my-12 ml-10 mr-4 flex w-full max-w-[1300px] flex-col md:mb-14 md:mt-17">
        <div
          class="flex max-w-[295px] items-center border-l-4 border-[--color-secondary-200] pl-6 text-28 font-bold text-additional-50 sm:max-w-full md:h-[115px] md:pl-10 md:text-5xl"
        >
          <span>{{ $t("pages.terms_and_conditions.header") }}</span>
        </div>
      </div>
    </div>
    <div
      v-if="canBypassTermsAndConditionsSection1 && canBypassTermsAndConditionsSection2 && isAuthenticated"
      class="mb-36 mt-14 flex items-center justify-center md:mb-56 md:mt-20"
    >
      <div class="flex h-32 flex-col items-center justify-center">
        <h2 class="mb-8 max-w-3xl text-center text-2xl font-bold text-primary sm:text-4xl">
          {{ $t("pages.terms_and_conditions.blanc_message") }}
        </h2>
      </div>
    </div>
    <div v-else class="z-10 flex max-w-[1300px] flex-col scroll-smooth sm:mx-auto">
      <div class="mb-2 flex flex-col md:mx-24">
        <div v-for="(item, index) in termsAndConditionsBlocks" :key="index" class="last:mb-3">
          <template v-if="!item.hidden">
            <div
              class="mt-7 flex w-full grow flex-row items-center justify-center bg-primary p-6 sm:mt-10 sm:rounded-t-md"
            >
              <h2 :id="item.content.title" class="text-18 font-bold text-additional-50 sm:text-[22px]">
                {{ t(item.content.title) }}
              </h2>
              <button
                type="button"
                size="md"
                class="op-terms__information__button ml-auto flex min-h-[36px] min-w-[36px] items-center justify-center sm:mr-6"
                :class="{ 'op-terms__information__button_active': item.opened }"
                @click="item.opened = !item.opened"
              >
                <img :class="{ 'rotate-180': item.opened }" src="/static/images/opus/faq/down.svg" alt="chevron" />
              </button>
            </div>
            <div
              v-if="item.opened"
              :class="{ 'op-terms__information__text-container_active': item.opened }"
              class="op-terms__information__text-container flex flex-col px-2 py-6 sm:pl-8"
            >
              <div class="flex flex-row items-center justify-start">
                <p
                  v-html-safe="item.content.text"
                  :class="{ 'op-terms__information__text_active': item.opened }"
                  class="op-terms__information__text text-16 mr-6 w-full font-bold text-[color:#003B57] sm:mr-28"
                />
              </div>
              <VcCheckbox
                :model-value="item.isAccepted"
                :disabled="isTermsAccepted || !isAuthenticated"
                class="my-2"
                @change="item.isAccepted = !item.isAccepted"
              >
                <span class="text-12 font-medium">
                  {{ $t("pages.terms_and_conditions.checkbox_label_" + item.content.id) }}</span
                >
              </VcCheckbox>

              <div class="ml-auto mr-2 flex">
                <VcButton
                  v-if="!conditionsApproved"
                  class="w-48 font-bold"
                  size="md"
                  :disabled="!item.isAccepted || isTermsAccepted || !isAuthenticated"
                  @click="updateShowDetails(index, item.content.title)"
                >
                  {{ $t("pages.terms_and_conditions.button_next") }}
                </VcButton>
                <VcButton
                  v-if="conditionsApproved"
                  class="w-48 font-bold"
                  size="md"
                  :loading="loading"
                  :disabled="!conditionsApproved || isTermsAccepted || !isAuthenticated || loading"
                  @click="onAccept"
                >
                  {{ $t("pages.terms_and_conditions.submit_button") }}
                </VcButton>
              </div>
            </div>
          </template>
        </div>
        <div class="my-10">
          <VcButton
            :disabled="!canDownload"
            class="flex-start ml-6 mr-auto flex md:mx-auto"
            variant="outline"
            prepend-icon="download"
            @click="downloadTerms"
          >
            {{ $t("common.buttons.download_pdf") }}
          </VcButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { breakpointsTailwind, useBreakpoints } from "@vueuse/core";
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { usePageHead } from "@/core/composables";
import { TERMS_AND_CONDITIONS_LIST } from "@/core/constants";
import { useUser } from "@/shared/account/composables";
import { useNotifications } from "@/shared/notification";
import { TERMS_RELEASE_DATE, getDateWithoutTime } from "@/shared/opus";
import type { TermsAndConditionsBlockType } from "@/shared/opus";

const router = useRouter();
const { t } = useI18n();
const breakpoints = useBreakpoints(breakpointsTailwind);
const isMobile = breakpoints.smaller("lg");
const {
  loading,
  user,
  acceptTerms,
  isTermsAccepted,
  isAuthenticated,
  acceptedSections,
  termsAcceptanceDate,
  canBypassTermsAndConditionsSection1,
  canBypassTermsAndConditionsSection2,
} = useUser();
const notifications = useNotifications();

const canDownload = ref(false);
const all_terms = "2024-10-09_All_Terms.pdf";
const micpa = "2024-10-09_MICPA.pdf";
const terms_of_use = "2024-10-09_Terms_of_Use.pdf";

usePageHead({
  title: t("pages.terms_and_conditions.meta.title"),
});

const termsAndConditionsBlocks = ref<TermsAndConditionsBlockType[]>([]);

const conditionsApproved = computed(() => {
  return termsAndConditionsBlocks.value.every((item: TermsAndConditionsBlockType) => item.isAccepted);
});

function downloadTerms() {
  let fileToDownload = "";

  if (!termsAndConditionsBlocks.value[0].hidden && !termsAndConditionsBlocks.value[1].hidden) {
    fileToDownload = all_terms;
  } else if (!termsAndConditionsBlocks.value[1].hidden) {
    fileToDownload = terms_of_use;
  } else if (!termsAndConditionsBlocks.value[0].hidden) {
    fileToDownload = micpa;
  }

  if (fileToDownload) {
    const link = document.createElement("a");
    link.href = `/static/${fileToDownload}`;
    link.download = fileToDownload;
    link.click();
  }
}

function updateShowDetails(index: number, title: string): void {
  termsAndConditionsBlocks.value[index].opened = false;
  termsAndConditionsBlocks.value[index + 1].opened = true;
  scrollTo(title);
}

async function onAccept() {
  await acceptTerms();

  !user.value?.passwordExpired && !!user.value?.temporaryPassword
    ? void router.push({ name: "CategoriesAndSuppliers", query: { welcome: "" } })
    : void router.push({ name: "CategoriesAndSuppliers" });
}

const scrollTo = function (id: string) {
  const element = document.getElementById(id);
  if (element) {
    const elementPosition = element!.getBoundingClientRect().top;
    const currentScrollPosition = window.pageYOffset;
    const offset = elementPosition + currentScrollPosition;
    window.scrollTo({ top: offset, behavior: "smooth" });
  }
};

function isSectionHidden(sectionName: string, sectionValue: boolean) {
  if (!acceptedSections.value) {
    return sectionValue;
  }

  const sectionRegex = new RegExp(`${sectionName}:(Yes|No);`);
  const sections = acceptedSections?.value as string;
  const match = sections?.match(sectionRegex);

  return match && match[1] === "No" ? true : sectionValue;
}

function formatDate(date: Date): string {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}

onMounted(() => {
  if (!isAuthenticated.value) {
    void router.push({ name: "SignIn" });
  }

  if (termsAcceptanceDate.value) {
    const acceptDate = new Date(termsAcceptanceDate.value);

    const formattedCurrentDate = getDateWithoutTime(new Date());
    const formattedAcceptDate = getDateWithoutTime(acceptDate);
    const termsReleaseDate = TERMS_RELEASE_DATE.split("T")[0];

    if (formattedAcceptDate < termsReleaseDate && formattedCurrentDate >= termsReleaseDate) {
      notifications.warning({
        text: t("pages.terms_and_conditions.expired_notification"),
        duration: 20000,
        single: true,
      });
    }
  }

  termsAndConditionsBlocks.value = [
    {
      hidden: isSectionHidden("Section1", canBypassTermsAndConditionsSection1.value),
      content: TERMS_AND_CONDITIONS_LIST[0],
      opened: false,
      isAccepted: isTermsAccepted.value || canBypassTermsAndConditionsSection1.value,
    },
    {
      hidden: isSectionHidden("Section2", canBypassTermsAndConditionsSection2.value),
      content: TERMS_AND_CONDITIONS_LIST[1],
      opened: false,
      isAccepted: isTermsAccepted.value || canBypassTermsAndConditionsSection2.value,
    },
  ];

  canDownload.value = !termsAndConditionsBlocks.value[1].hidden || !termsAndConditionsBlocks.value[0].hidden;
});
</script>

<style lang="scss" scoped>
.op-terms {
  flex: 1;

  &__header-subtitle {
    border-left: 5px solid;
    border-color: #ffb294;
    line-height: 64px;
  }

  &__information__text-container {
    box-shadow: 1px 2px 9px rgba(0, 0, 0, 0.1);
    background-color: white;
    border-radius: 4px;
    list-style: auto;
  }

  &__information__text {
    color: #003b57;
    font-size: 10px;
  }

  &__information__text_active {
    font-weight: 500;
  }

  &__information__button {
    background-color: rgba(0, 0, 0, 0);
    border: 2px solid white;
    border-radius: 5px;
    color: white;
    box-sizing: border-box;
  }

  &__information__button_active {
    border: 2px solid white;
    color: white;
    padding: 10px 8px 10px 8px;
  }
}
tr {
  border: 0.5pt solid #00205c !important;
}
</style>
